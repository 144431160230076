import privacyCover from "../../Assets/privacy/privacyBackground.webp";
import privacyBackground from "../../Assets/privacy/privacyCover.webp";
import Footer from "../../Layouts/Footer";
import Logo from "../../Assets/Logo/Logo.png";
import Fade from "react-reveal/Fade";

export default function TermsOfUse() {
	return (
		<div>
			<div
				className="Privacy-cover"
				style={{
					// background: "#4D917E",
					// paddingTop: "2rem",
					backgroundImage: `url(${privacyCover})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "top",

					// display: "flex",
					// alignItems: "center",
					// justifyContent: "space-around",
					// backgroundPosition: "right top",
				}}
			>
				<Fade bottom>
					<h1>Пользовательское соглашение</h1>
				</Fade>
			</div>

			<div className="privacy-policy mx-5">
				<p>
					Настоящее Соглашение является публичной офертой и определяет условия
					использования материалов и сервисов, размещенных на сайте в сети
					Интернет по адресу: https://elarteclinic.com посетителями и
					пользователями данного интернет-сайта (далее - Сайт).
				</p>
				<p>1. Общие условия</p>
				<p>
					1.1. Сайт создан в целях информирования и консультирования
					пользователей об услугах клиники El Arte.
				</p>
				<p>
					1.2. Использование материалов и сервисов Сайта регулируется нормами
					действующего законодательства Российской Федерации.
				</p>
				<p>
					1.3. Получая доступ к материалам Сайта, Пользователь считается
					присоединившимся к настоящему Соглашению.
				</p>
				<p>2. Обязательства Пользователя</p>
				<p>
					2.1. Пользователь соглашается не предпринимать действий и не оставлять
					комментарии и записи, которые могут рассматриваться как нарушающие
					российское законодательство или нормы международного права, в том
					числе в сфере интеллектуальной собственности, авторских и/или смежных
					прав, общепринятые нормы морали и нравственности, а также любых
					действий, которые приводят или могут привести к нарушению нормальной
					работы Сайта и сервисов Сайта.
				</p>
				<p>
					2.2. Использование материалов Сайта без согласия правообладателей не
					допускается.
				</p>
				<p>
					2.3. При цитировании материалов Сайта, включая охраняемые авторские
					произведения, ссылка на Сайт обязательна.
				</p>
				<p>
					2.4. Администрация Сайта не несет ответственности за посещение и
					использование им внешних ресурсов, ссылки на которые могут содержаться
					на Сайте.
				</p>
				<p>
					2.5. Администрация Сайта не несет ответственности и не имеет прямых
					или косвенных обязательств перед Пользователем в связи с любыми
					возможными или возникшими потерями или убытками, связанными с любым
					содержанием Сайта, регистрацией авторских прав и сведениями о такой
					регистрации, товарами или услугами, доступными на или полученными
					через внешние сайты или ресурсы либо иные контакты Пользователя, в
					которые он вступил, используя размещенную на Сайте информацию или
					ссылки на внешние ресурсы.
				</p>
				<p>3. Прочие условия</p>
				<p>
					3.1. Все возможные споры, вытекающие из настоящего Соглашения или
					связанные с ним, подлежат разрешению в соответствии с действующим
					законодательством Российской Федерации.
				</p>
				<p>
					3.2. Признание судом какого-либо положения Соглашения недействительным
					или не подлежащим принудительному исполнению не влечет
					недействительности иных положений Соглашения.
				</p>
				<p>
					3.3. Бездействие со стороны Администрации Сайта в случае нарушения
					кем-либо из Пользователей положений Соглашения не лишает Администрацию
					Сайта права предпринять позднее соответствующие действия в защиту
					своих интересов и защиту авторских прав на охраняемые в соответствии с
					законодательством материалы Сайта.
				</p>
				<p>
					3.4. Администрация Сайта вправе в любое время в одностороннем порядке
					изменять условия настоящего Соглашения. Такие изменения вступают в
					силу по истечении 3 (трех) дней с момента размещения новой версии
					Соглашения на сайте. При несогласии Пользователя с внесенными
					изменениями он обязан отказаться от доступа к Сайту, прекратить
					использование материалов и сервисов Сайта.
				</p>
				<p>
					3.5. Переходя по ссылке https://elarteclinic.com, Пользователь
					подтверждает, что принимает условия настоящего Соглашения, а также
					Политики конфиденциальности Сайта, являющейся неотъемлемой частью
					настоящего Соглашения и размещенной на странице по адресу:
					https://elarteclinic.com/privacy.
				</p>
			</div>

			<div
				className="Privacy-logo"
				style={{
					// background: "#4D917E",
					// paddingTop: "2rem",
					backgroundImage: `url(${privacyBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "bottom",
					height: "50vh",

					// display: "flex",
					// alignItems: "center",
					// justifyContent: "space-around",
					// backgroundPosition: "right top",
				}}
			>
				<img
					className="privacy-logo-left"
					src={Logo}
					alt={decodeURIComponent(Logo.split("/").pop().split(".")[0])}
				></img>
			</div>
			<Footer />
		</div>
	);
}
